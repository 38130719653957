<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.partner_doctor") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
          <el-date-picker
            size="mini"
            v-model="filterForm.start_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.start_date')"
          ></el-date-picker>
          <el-date-picker
            size="mini"
            v-model="filterForm.end_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.end_date')"
          ></el-date-picker>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
        "
      >
        <el-button
          v-can="'organizations.create'"
          size="mini"
          @click="drawer.create.status = true"
          icon="el-icon-circle-plus-outline"
        >
          {{ $t("message.create") }}
        </el-button>
        <export-excel
          v-can="'organizations.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Партнер доктор"
          name="Партнер_доктор.xls"
        >
          <el-button size="mini">
            <i class="el-icon-document-delete"></i> Excel
          </el-button>
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'partnerDoctor'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.name"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.partner_clinics"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.phone_number"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.updated_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>

          <th v-if="columns.name.show">
            <el-input
              size="mini"
              v-model="filterForm.name"
              :placeholder="columns.name.title"
              clearable
            >
            </el-input>
          </th>

          <th v-if="columns.partner_clinics.show">
            <el-select
              :placeholder="$t('message.class')"
              v-model="filterForm.partner_clinic_id"
              clearable
              filterable
              size="mini"
            >
              <el-option
                v-for="item in clinics"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </th>

          <th v-if="columns.phone_number.show"></th>
          <th v-if="columns.updated_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="partnerDoctor in list" :key="partnerDoctor.id">
          <td v-if="columns.id.show">{{ partnerDoctor.id }}</td>
          <td v-if="columns.name.show">{{ partnerDoctor.name }}</td>
          <td v-if="columns.partner_clinics.show">
            <div v-if="partnerDoctor.partner_clinics.length > 0">
              <span
                v-for="(item, index) in partnerDoctor.partner_clinics"
                :key="index"
              >
                <span
                  v-if="index == partnerDoctor.partner_clinics.length - 1"
                  >{{ item.name }}</span
                >
                <span v-else> {{ item.name + ", " }} </span>
              </span>
            </div>
          </td>
          <td v-if="columns.phone_number.show">
            {{ partnerDoctor.phone_number }}
          </td>
          <td v-if="columns.updated_at.show">
            {{ partnerDoctor.updated_at }}
          </td>
          <td v-if="columns.created_at.show">
            {{ partnerDoctor.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <crm-setting-dropdown
              :model="partnerDoctor"
              name="partner_doctors"
              :actions="actions"
              @edit="edit"
              @delete="destroyModel"
            >
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>
    <!-- <el-drawer
      :visible.sync="drawerCreate"
      size="95%"
      :drawer="drawerCreate"
      @close="reloadIfChanged"
      class="mytab_IdP"
    >
      <div>
        <crm-create
          :reloadModel="reopenUpdate"
          @c-close="reloadIfChanged('drawerCreate')"
        ></crm-create>
      </div>
    </el-drawer> -->
    <!-- <el-drawer
      :visible.sync="drawerUpdate"
      size="95%"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
      class="mytab_IdP"
    >
      <div>
        <crm-update
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
        ></crm-update>
      </div>
    </el-drawer> -->

    <el-drawer
      size="90%" :wrapperClosable="false"
      :visible.sync="drawer.update.status"
      :ref="drawer.update.name"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <crm-update
        :ref="drawer.update.component"
        :drawer-name="drawer.update.name"
      >
      </crm-update>
    </el-drawer>

    <el-drawer
      size="90%" :wrapperClosable="false"
      :visible.sync="drawer.create.status"
      :ref="drawer.create.name"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <crm-create
        :ref="drawer.create.component"
        :drawer-name="drawer.create.name"
      ></crm-create>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/listNew";
// import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [list], //drawer
  name: "partnerDoctorsList",
  components: {
    CrmUpdate,
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "DrawerCreate",
        },
        update: {
          name: "update",
          status: false,
          component: "DrawerUpdate",
        },
      },
      selectedModel: null,
    };
  },
  computed: {
    ...mapGetters({
      list: "partnerDoctor/list",
      columns: "partnerDoctor/columns",
      pagination: "partnerDoctor/pagination",
      filter: "partnerDoctor/filter",
      sort: "partnerDoctor/sort",
      clinics: "partnerClinic/inventory_all",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {
    if (this.clinics && this.clinics.length == 0) {
      this.loadClinics();
    }
  },
  async mounted() {
    this.controlExcelData();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "partnerDoctor/index",
      updateSort: "partnerDoctor/updateSort",
      updateFilter: "partnerDoctor/updateFilter",
      updateColumn: "partnerDoctor/updateColumn",
      updatePagination: "partnerDoctor/updatePagination",
      editModel: "partnerDoctor/show",
      empty: "partnerDoctor/empty",
      delete: "partnerDoctor/destroy",
      refreshData: "partnerDoctor/refreshData",
      loadClinics: "partnerClinic/inventoryAll",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawer.update.status = true;
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    sortedClinics(doctors) {
      let array = [];
      if (doctors.length) {
        doctors.forEach((value, index) => {
          array.push(value.name);
        });
      }
      return array.toString();
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            switch (column.column) {
              case "partner_clinic_id":
                continue;
              case "partner_clinics":
                this.excel_fields[column.title] = {
                  field: "partner_clinics",
                  callback: (partner_clinics) => {
                    return this.sortedClinics(partner_clinics);
                  },
                };
                break;

              default:
                this.excel_fields[column.title] = column.column;
            }
          }
        }
      }
    },
    destroyModel(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
